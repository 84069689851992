import { getServiceUrl, getMapUrl } from '../utils/externalPages';

export const HEADER_MENU_ITEMS = [
  {
    label: 'services',
    data: [
      {
        title: 'home',
        path: getServiceUrl(),
      },
      {
        title: 'kriptosearch',
        path: `${getServiceUrl()}/kriptostore/checkout?operation=search`,
      },
      {
        title: 'kriptostore',
        path: `${getServiceUrl()}/kriptostore/checkout?operation=own`,
      },
      {
        title: 'payments',
        path: `${getServiceUrl()}/kriptopayment`,
      },
      {
        title: 'maps',
        path: getMapUrl(),
      },
      {
        title: 'marketplace',
        path: 'https://marketplace.kriptonmarket.com/',
      },
      {
        title: 'movements',
        path: `${getServiceUrl()}/movements`,
      },
    ],
    dropdownMenuMinWidth: 200,
  },
  {
    label: 'commerces',
    data: [
      {
        title: 'posPayment',
        path: `/`,
      },
      {
        title: 'paymentHistory',
        path: `/payments`,
      },
      {
        title: 'myShop',
        collapseData: [
          {
            title: 'companyInformation',
            path: `/my-store`,
          },
          {
            title: 'coinsTokens',
            path: `/my-store/tokens-currencies`,
          },
          {
            title: 'shops',
            path: `/my-store/locales`,
          },
        ],
      },
      {
        title: 'commercialData',
        path: `/profile`,
      },
      {
        title: 'settings',
        path: `/settings`,
      },
      {
        title: 'report',
        path: `/reports`,
      },
      {
        title: 'users',
        path: `/users`,
      },
    ],
    dropdownMenuMinWidth: 200,
  },
  {
    label: 'wallet',
    data: [
      {
        title: 'home',
        path: `${getServiceUrl()}/wallets`,
      },
      {
        title: 'profile',
        collapseData: [
          // {
          //   title: 'personalInformation',
          //   path: `${getServiceUrl()}/personal-information`,
          // },
          // {
          //   title: 'domicilie',
          //   path: `${getServiceUrl()}/domicilial-information`,
          // },
          {
            title: 'verificationCenter',
            path: `${getServiceUrl()}/verification-center`,
          },
          {
            title: 'security',
            path: `${getServiceUrl()}/security`,
          },
        ],
      },
      {
        title: 'banks',
        path: `${getServiceUrl()}/banks`,
      },
      {
        title: 'cards',
        path: `${getServiceUrl()}/cards`,
      },
      {
        title: 'wallets',
        path: `${getServiceUrl()}/wallets/list`,
      },
      {
        title: 'cashIn',
        path: `${getServiceUrl()}/broker?operation=cash-in&medium=Banco`,
      },
      {
        title: 'cashOut',
        path: `${getServiceUrl()}/broker?operation=cash-out&medium=Banco`,
      },
      {
        title: 'swap',
        path: `${getServiceUrl()}/swaps`,
      },
    ],
    dropdownMenuMinWidth: 200,
  },
];
